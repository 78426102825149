@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: 'Quicksand', sans-serif;
}

body {
    margin: 0;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
    border-bottom: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.custom-layout-content {
    background-color: white;
    position: relative;
    padding: 24px;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.custom-placeholder-select .ant-select-selection-placeholder {
    color: black;
    opacity: 1;
}

/* Số lượng dòng cho phép  */
.number-of-line-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.button-shadow {
    box-shadow: 1.5px 1.5px 0px 0px rgba(204, 204, 204, 0.5);
    -webkit-box-shadow: 1.5px 1.5px 0px 0px rgba(204, 204, 204, 0.5);
    -moz-box-shadow: 1.5px 1.5px 0px 0px rgba(204, 204, 204, 0.5);
}

.header-shadow {
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2);
}

.custom-dot {
    list-style: none;
    margin: 32px 0px;
}

.custom-dot li::before {
    content: "\2022";
    color: #6E6601;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.custom-progress {
    height: 27px;
}

.custom-progress .ant-progress-bg {
    height: 27px !important;
    border-radius: 0.5rem !important;
}

.custom-progress .ant-progress-outer {
max-width: 98%;
}

.custom-progress span {
    font-weight: 600;
    }

.custom-progress .ant-progress-inner {
    border-radius: 0.5rem !important;
    max-width: 98%;

}

.disable-dots  {
    pointer-events: none;
}


.custom-carousel .slick-dots li button {
    background: #888;
}

.custom-carousel .slick-dots li.slick-active button {
    background: #37B0F4;
}

.text-shadow {
    text-shadow:
	-1px -1px 0 rgba(134, 134, 134, 0.05),
	 0   -1px 0 rgba(134, 134, 134, 0.05),
	 1px -1px 0 rgba(134, 134, 134, 0.05),
	 1px  0   0 rgba(134, 134, 134, 0.05),
	 1px  1px 0 rgba(134, 134, 134, 0.05),
	 0    1px 0 rgba(134, 134, 134, 0.05),
	-1px  1px 0 rgba(134, 134, 134, 0.05),
	-1px  0   0 rgba(134, 134, 134, 0.05);
}

.select-custom > div {
    border-radius: 0.5rem !important;
}

.custom-progress > span {
    font-size: 1rem; 
    color: #595959;
}

.login-title-1 {
    text-shadow:
	-1px -1px 0 rgba(0, 0, 0, 0),
	 0   -1px 0 rgba(0, 0, 0, 0),
	 1px -1px 0 rgba(0, 0, 0, 0),
	 1px  0   0 rgba(0, 0, 0, 0),
	 1px  1px 0 rgba(0, 0, 0, 0),
	 0    1px 0 rgba(0, 0, 0, 0),
	-1px  1px 0 rgba(0, 0, 0, 0),
	-1px  0   0 rgba(0, 0, 0, 0);
    font-family: 'Roboto';
    color: #E3342C;
    font-weight: 900;
    text-align: center;
    padding-top: 4.8vh;
    margin-bottom: 0px;
}

.login-title-2 {
    font-size: 2rem;
    font-family: 'Roboto';
    color: #fff;
    font-weight: 500;
    line-height: 2.4rem;
    margin-bottom: 0px;
}

.login-title-3 {
    text-shadow:
	-1px -1px 0 rgba(213, 50, 48, 1),
	 0   -1px 0 rgba(213, 50, 48, 1),
	 1px -1px 0 rgba(213, 50, 48, 1),
	 1px  0   0 rgba(213, 50, 48, 1),
	 1px  1px 0 rgba(213, 50, 48, 1),
	 0    1px 0 rgba(213, 50, 48, 1),
	-1px  1px 0 rgba(213, 50, 48, 1),
	-1px  0   0 rgba(213, 50, 48, 1);
    font-size: 2rem;
    font-family: 'Roboto';
    color: rgba(252, 196, 38, 1);
    font-weight: 900;
    line-height: 2.4rem;
    margin-top: 2.4rem;
}

.login-mobile-title-1 {
    text-shadow:
	-1px -1px 0 rgba(0, 0, 0, 0),
	 0   -1px 0 rgba(0, 0, 0, 0),
	 1px -1px 0 rgba(0, 0, 0, 0),
	 1px  0   0 rgba(0, 0, 0, 0),
	 1px  1px 0 rgba(0, 0, 0, 0),
	 0    1px 0 rgba(0, 0, 0, 0),
	-1px  1px 0 rgba(0, 0, 0, 0),
	-1px  0   0 rgba(0, 0, 0, 0);
    font-family: 'Roboto';
    color: rgba(255, 85, 78, 1);
    font-weight: 900;
    text-align: center;
    padding-top: 1.6vh;
    margin-bottom: 0px;
}

.login-mobile-title-2 {
    font-size: 1rem;
    font-family: 'Roboto';
    color: #fff;
    font-weight: 500;
    line-height: 1.8rem;
    
}

.login-mobile-title-3 {
    text-shadow:
	-1px -1px 0 rgba(213, 50, 48, 1),
	 0   -1px 0 rgba(213, 50, 48, 1),
	 1px -1px 0 rgba(213, 50, 48, 1),
	 1px  0   0 rgba(213, 50, 48, 1),
	 1px  1px 0 rgba(213, 50, 48, 1),
	 0    1px 0 rgba(213, 50, 48, 1),
	-1px  1px 0 rgba(213, 50, 48, 1),
	-1px  0   0 rgba(213, 50, 48, 1);
    font-size: 1rem;
    font-family: 'Roboto';
    color: rgba(252, 196, 38, 1);
    font-weight: 900;
    line-height: 1.8rem;
    margin-top: 1.8rem;
}

@media only screen and (max-width: 320px) {
    .login-mobile-title-1 {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }
    .login-mobile-title-2 {
        font-size: 0.6rem;
        line-height: 1.6rem;
    }
    .login-mobile-title-3{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 680px) {
    .login-mobile-title-1 {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }
    .login-mobile-title-2 {
        font-size: 0.8rem;
        line-height: 1.6rem;
    }
    .login-mobile-title-3{
        font-size: 0.9rem;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 50%);
    width: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, rgba(255,228,146,1) 0%, rgba(250,195,0,1) 50%);
    width: 5px;
  }


video::-webkit-media-controls-timeline {
    display: none;
}

.disable-play::-webkit-media-controls {
    display: none;
}